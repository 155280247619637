import get from 'lodash/get';
import { flatten } from 'lodash';

import { isToggleField } from './helpers';
import { ToggleOption, MultiSelectOption } from './car-status-data';
import type {
  CarStatusDataQuery,
  EquipmentItem,
  EquipmentItemInput,
  EquipmentGroupItemInput,
} from 'gql/graphql';
import type { EquipmentItemType } from './types';

const emptyValues = [MultiSelectOption.NOT_PRESENT, ToggleOption.NO];

export const graphqlDataToSchema = (data: CarStatusDataQuery | undefined) => {
  const getData = (field: string, def?: string | boolean | Array<null>) => get(data, field) ?? def;
  const detailsArr = getData('inspection.vehicle.exterior.colorDetails', []);
  const isOriginal = detailsArr[0]?.isOriginal;
  const isMetallic = detailsArr[0]?.effects?.includes('METALLIC');
  const fuelType = data?.inspection?.vehicle?.engine?.fuelType;

  const otherInformation = {
    exteriorColor: getData('inspection.vehicle.exterior.mainColor'),
    isMetallic: isMetallic || false,
    isOriginalColor: isOriginal || false,
    upholstery: getData('inspection.vehicle.interior.upholstery'),
    isUpholsteryOriginal: getData(
      'inspection.vehicle.interior.isUpholsteryOriginal',
      false,
    ),
    keyCount: getData('inspection.vehicle.condition.keyCount'),
    seatCount: getData('inspection.vehicle.interior.seatCount'),
    hasTuning: getData(
      'inspection.meta.evaluation.hasTuning',
      ToggleOption.UNSELECTED,
    ),
    tuningNote: getData('inspection.meta.evaluation.tuningNote'),
    otherNote: getData('inspection.meta.evaluation.otherNote'),
    hasLpg: getData(
      'inspection.meta.evaluation.hasLpg',
      ToggleOption.UNSELECTED,
    ),
    lpgNote: getData('inspection.meta.evaluation.lpgNote'),
  };

  const filterEquipments = ({ items = [] }) => items.length;
  const filterEquipmentItems = ({ exists }: EquipmentItem) => exists;
  const mapEquipmentItemsResult = ({ source, id }: EquipmentItemInput) => `${source},${id}`;
  const mapEquipmentItemsToggle = (
    exists: boolean,
    { exists: itemExists, source, id }: EquipmentItem,
  ) => {
    if (exists === null) {
      return ToggleOption.UNSELECTED;
    }

    if (itemExists) {
      return `${source},${id}`;
    }

    return ToggleOption.NO;
  };

  const buildEquipmentSchema = ({ exists, items = [], name }: { exists: boolean; items: Array<EquipmentItem>, name: string }) => {
    let value;
    const isToggle = isToggleField(items);

    if (isToggle) {
      value = mapEquipmentItemsToggle(exists, items[0]);
    } else if (exists === false) {
      value = [MultiSelectOption.NOT_PRESENT];
    } else {
      value = items.filter(filterEquipmentItems).map(mapEquipmentItemsResult);
    }

    return { value, isToggle, name };
  };

  const equipmentItems = getData('getMandatoryEquipment', [])
    .filter(filterEquipments)
    .map(buildEquipmentSchema)
    .sort((a: EquipmentItemType, b: EquipmentItemType) => {
      if (a.isToggle === b.isToggle) return 0;
      if (a.isToggle && !b.isToggle) return 1;
      return -1;
    });
  const equipments = {
    items: [...equipmentItems],
  };

  return { otherInformation, equipments, fuelType };
};

const removeEmptyValues = ({ isToggle, value }: { isToggle: boolean, value: string | string[] }) => {
  if (isToggle) {
    return value !== ToggleOption.UNSELECTED;
  }

  return value.length >= 1;
};
type TransformToggleToMutationArgs = {
  isToggle: boolean;
  name: string;
  value: string[];
}

export const mapEquipmentsToMutation = (equipments: { items: Array<EquipmentItemType> }): EquipmentGroupItemInput[] => {

  const transformToggleToMutation = ({ isToggle, ...item }: TransformToggleToMutationArgs) => {
    const value = item.value;

    if (isToggle) {
      return {
        ...item,
        value: !Array.isArray(value) && emptyValues.includes(value) ? [] : [value],
      };
    }

    return {
      ...item,
      value: value?.length === 1 && emptyValues.includes(value[0]) ? [] : value,
    };
  };

  const mapEquipmentToMutation = (equipment: { value: string[] | string[][]; name: string }) => {
    return {
      group: equipment.name,
      equipmentItems: (equipment?.value as string[])?.map((stringValue = '') => {
        const [source, id] = stringValue.split(',');
        return { source, id: Number(id) };
      }),
    };
  };

  const result: EquipmentGroupItemInput[] = flatten(
    get(equipments, 'items', [])
      .filter(removeEmptyValues)
      .map(transformToggleToMutation)
      .map(mapEquipmentToMutation),
  );

  return result;
};

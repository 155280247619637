import * as yup from 'yup';
import type { Translations } from 'shared/utils/translations';
import {
  getRequiredMessage,
  requiredBoolean,
  requiredString,
  requiredNum,
  requiredToggle,
  requiredArray,
} from 'shared/utils/yupValidationHelpers';

import {
  Colors,
  Upholstery,
  ToggleOption,
} from './car-status-data';

const upholsteryValidation = (optionalSchema: boolean, translations: Translations) => {
  if (!optionalSchema) {
    return yup
      .string()
      .oneOf(Object.keys(Upholstery()))
      .required(getRequiredMessage(translations));
  }

  return yup
    .string()
    .nullable()
    .oneOf(Object.keys(Upholstery()));
};

const requiredColorString = (optionalSchema: boolean, translations: Translations) => {
  if (!optionalSchema) {
    return yup
      .string()
      .oneOf(Object.keys(Colors))
      .required(getRequiredMessage(translations));
  }

  return yup.string().nullable().oneOf(Object.keys(Colors));
};

const requiredNumberNullable = (optionalSchema: boolean, translations: Translations) => {
  return requiredNum(optionalSchema, translations).nullable();
};

const nonBooleanToggle = (optionalSchema: boolean, translations: Translations) => {
  if (!optionalSchema) {
    return yup
      .mixed()
      .test(
        'required',
        getRequiredMessage(translations, 'toggle'),
        (value) => value !== ToggleOption.UNSELECTED,
      );
  }

  return yup.mixed();
};

const CarStatusEquipmentSchema = (translations: Translations, optionalSchema: boolean) => {
  return yup
    .object({
      items: yup.array(
        yup.object({
          isToggle: yup.bool(),
          value: yup.mixed().when('isToggle', {
            is: true,
            then: nonBooleanToggle(optionalSchema, translations),
            otherwise: requiredArray(optionalSchema, translations),
          }),
        }),
      ),
    })
    .default({ items: [] });
};

const otherInformationSchema = (translations: Translations, optionalSchema: boolean) => {
  return yup
    .object()
    .transform(
      ({ exteriorColor, hasTuning, hasLpg, upholstery, ...fields }) => ({
        ...fields,
        hasTuning:
          hasTuning !== ToggleOption.UNSELECTED ? hasTuning : undefined,
        hasLpg: hasLpg !== ToggleOption.UNSELECTED ? hasLpg : undefined,
        exteriorColor: exteriorColor !== null ? exteriorColor : undefined,
        upholstery: upholstery !== null ? upholstery : undefined,
      }),
    )
    .shape({
      exteriorColor: requiredColorString(optionalSchema, translations),
      isMetallic: requiredBoolean(optionalSchema),
      isOriginalColor: requiredBoolean(optionalSchema),
      upholstery: upholsteryValidation(optionalSchema, translations),
      isUpholsteryOriginal: requiredBoolean(optionalSchema),
      keyCount: requiredNumberNullable(optionalSchema, translations),
      seatCount: requiredNumberNullable(optionalSchema, translations),
      hasTuning: requiredToggle(optionalSchema, translations),
      tuningNote: yup.string().when('hasTuning', {
        is: true,
        then: requiredString(optionalSchema, translations),
        otherwise: yup.string().default(''),
      }),
      hasLpg: requiredToggle(optionalSchema, translations),
      lpgNote: yup.string().when('hasLpg', {
        is: true,
        then: requiredString(optionalSchema, translations),
        otherwise: yup.string().default(''),
      }),
    });
};

const CarStatusSchema = (translations: Translations, optionalSchema: boolean) => {
  return yup
    .object()
    .shape({
      otherInformation: otherInformationSchema(translations, optionalSchema),
      equipments: CarStatusEquipmentSchema(translations, optionalSchema),
    })
};

export { CarStatusSchema };
